import React from "react";
import { object, bool, string } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Heading from "../Base/HeadingBuilder";
import CtaSecondary from "../Base/CtaSecondary";
import background from "../../images/svg/quarter-circle.svg";
import useStartMotion from "../../hooks/use-start-motion";

const TitleWrapperComponent = React.forwardRef((props, ref) => (
  <div className="relative flex-40" ref={ref}>
    <Heading level={2} injectionType={1} className="xl:pr-28" withBlueSymbol>
      {props.sectionData.title}
    </Heading>

    <CtaSecondary
      className="hidden lg:flex"
      title={props.sectionData.SecondaryCta.ctaTitle}
      target={props.sectionData.SecondaryCta.ctaTarget}
      large
      style={{ border: "none" }}
    />
  </div>
));

const SubtextWrapperComponent = React.forwardRef((props, ref) => (
  <div className="flex-60" ref={ref}>
    <p className="text-black-200 text-p-large mb-6 lg:mb-8">
      {props.sectionData.subtext}
    </p>

    <CtaSecondary
      className="lg:hidden"
      title={props.sectionData.SecondaryCta.ctaTitle}
      target={props.sectionData.SecondaryCta.ctaTarget}
      large
      style={{ border: "none" }}
    />
  </div>
));

const MotionTitleWrapperComponent = motion(TitleWrapperComponent, {
  forwardMotionProps: true,
});

const MotionSubtextWrapperComponent = motion(SubtextWrapperComponent, {
  forwardMotionProps: true,
});

const WhyUsOverview = ({ className, sectionData, animations }) => {
  const { ref, controls } = useStartMotion();

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    // <section className="why-us-overview" ref={ref}>
    <section
      className={`
        ${className}
        max-w-1440 bg-black-800
        mx-4 lg:mx-8 px-6 xl:px-20 py-24 
        lg:flex lg:flex-row lg:items-center rounded-large relative
      `}
      ref={ref}
    >
      <img
        className="why-us-overview__bg"
        src={background}
        alt="section background"
      />

      <MotionTitleWrapperComponent
        sectionData={sectionData}
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={variants}
        transition={{ delay: 0.7, duration: 0.8 }}
      />

      <MotionSubtextWrapperComponent
        sectionData={sectionData}
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={variants}
        transition={{ delay: 0.2, duration: 0.8 }}
      />

      <style jsx>{`
        .why-us-overview__bg {
          position: absolute;
          top: 0;
          left: 0;
        }
      `}</style>
    </section>
  );
};

WhyUsOverview.propTypes = {
  className: string,
  // eslint-disable-next-line react/forbid-prop-types
  sectionData: object.isRequired,
  animations: bool,
};

WhyUsOverview.defaultProps = {
  className: "",
  animations: true,
};

export default WhyUsOverview;
